


















































































































































































































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import store from '@/store';
import { getAttendingAnswers, getVisitorContactById } from '@/api/visitors';
import { getFileById } from '@/api/files';
import isJson from '@/utils/isJson';
import Textarea from '@/components/form/textarea.vue';
import getYoutubeId from '@/utils/getYoutubeId';
import {
  IAttendingAnswer,
  IAttendingFieldsEntity,
  IVisitor,
  IVisitorContact,
} from '@/models/visitor';
import { createRocketInstantMessage, getRocketUser } from '@/api/chat';
import { IRocketRegisterResponse } from '@/models/chat';
import formatDate from '@/utils/formatDate';
import { ITextarea } from '@/components/form';
import { ISession } from '@/models/session';
import UserCard from '@/partials/UserCard.vue';
import SessionCard from '@/partials/SessionCard.vue';
import { IUserCard } from '@/partials';
import { fetchProductInteractions } from '@/api/productInteractions';
import { IProductInteraction } from '@/models/productInteraction';
import { createExhibitorComment, updateExhibitorComment } from '@/api/exhibitors';
import { getExhibitorSessions } from '@/api/sessions';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import { getEventInfo } from '@/api/events';

export default defineComponent({
  components: {
    Textarea,
    UserCard,
    SessionCard,
  },
  data() {
    return {
      fileLoading: false,
      fields: [] as IAttendingFieldsEntity[],
      formatDate: formatDate,
      comment: '',
      event: 0,
      exhibitor: 0,
      visitor: {} as IVisitorContact,
      commentLoading: false,
      visitorAnswers: [] as IAttendingAnswer[],
      visitorId: 0,
      logoUrl: '',
      videoUrl: '',
      visitorSessions: [] as ISession[],
      rocketChatUser: {} as IRocketRegisterResponse,
      productInteractions: [] as IProductInteraction[],
      commentTextarea: {
        id: 'comment-textarea',
        name: 'comment_textarea',
        placeholder: 'Enter your comment',
        value: '',
      } as ITextarea,
    };
  },
  setup() {
    const { translations, contextEvent } = useContext();
    return {
      translations,
      contextEvent,
    };
  },
  created() {
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.visitorId = parseInt(this.$route.params.visitor, 10);
    this.event = parseInt(this.$route.params.event, 10);

    getExhibitorSessions({
      event: this.event,
      exhibitor: this.exhibitor,
      visitor: this.visitorId,
      type: 'exhibitor',
      page_size: 6,
    }).then((response) => {
      this.visitorSessions = response.data.results;
    });

    getVisitorContactById(this.visitorId, this.exhibitor).then((response) => {
      this.visitor = response.data;
      this.commentTextarea = {
        ...this.commentTextarea,
        value: this.visitor.comment,
        placeholder: this.translations.exhibitors.visitors.enter_note_placeholder,
      };

      this.comment = this.visitor.comment;

      this.initDetails();
    });

    if (this.contextEvent.slug) {
      this.getVisitorInformation();
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          this.getVisitorInformation();
          unsubscribe();
        }
      });
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.commentTextarea = {
          ...this.commentTextarea,
          value: this.visitor.comment,
          placeholder: this.translations.exhibitors.visitors.enter_note_placeholder,
        };
        this.getVisitorInformation();
      }
    });
  },
  methods: {
    async initDetails() {
      if (this.visitor.user_account && this.visitor.id) {
        if (this.visitor.origin.product_interactions.length > 0) {
          // this.visitor.origin.product_interactions.forEach((interaction) => {
          //   fetchProductInteractionById(interaction.product_interaction).then((response) => {
          //     this.productInteractions.push(response.data);
          //   });
          // });

          fetchProductInteractions({
            page: 1,
            page_size: 100,
            exhibitor: this.exhibitor,
            visitor: this.visitor.id,
            event: this.event,
          }).then((response) => {
            this.productInteractions = response.data.results;
          });
        }
        const fieldsResponse = await getEventInfo(this.contextEvent);
        const answersResponse = await getAttendingAnswers(this.visitor.id);

        const answers = answersResponse.data.results;
        if (fieldsResponse.data) {
          const { fields } = fieldsResponse.data;
          if (fields) {
            this.fields = await Promise.all(
              fields.map(async (field) => {
                const answer = answers.find((answer) => answer.event_attending_field === field.id);
                if (answer) {
                  if (field.attending_field.validation.includes('image')) {
                    const fileResponse = await getFileById(answer.answer as any);
                    const { file } = fileResponse.data;
                    answer.answer = file;
                  }
                  return {
                    ...field,
                    value: answer.answer as string,
                  };
                }
                return field;
              }),
            );
          }
        }
      }
    },

    getVisitorInformation() {
      getEventInfo(this.contextEvent).then((response) => {
        let { fields } = response.data;
        if (fields) {
          fields = fields.filter((field) => !field.attending_field.hide_from_visitors);

          getAttendingAnswers(this.visitorId).then((response) => {
            this.visitorAnswers = [];
            response.data.results.map(async (answer) => {
              if (fields) {
                const field = fields.find(
                  (field) => answer && answer.event_attending_field === field.id,
                );
                if (field && answer.answer) {
                  // Text, Textarea, Select, Radio button todo: covered - take nonformatted answer

                  // Files (Validation Image, Square Image)
                  if (
                    ['image', 'square_image'].includes(field.attending_field.validation) &&
                    answer.answer !== ''
                  ) {
                    const fileResponse = await getFileById(answer.answer as any);
                    const { file } = fileResponse.data;
                    answer.answer = file;
                  }

                  // Checkbox
                  if (answer.answer && field.attending_field.field_type === 'checkbox') {
                    this.extractSelectedCheckboxes(answer);
                  }
                  // Youtube embedded link (Custom fields)
                  if (field.attending_field.title === 'BridgeEmbeddedVideo') {
                    this.videoUrl = this.getYoutubeEmbededLink(answer.answer);
                  }

                  if (
                    field.attending_field.title &&
                    field.attending_field.title.includes('Bridge')
                  ) {
                    return;
                  }

                  const visitorAnswer = {
                    ...answer,
                    title: field.attending_field.title,
                    field_type: field.attending_field.field_type,
                    validation: field.attending_field.validation,
                    hide_from_exhibitors: field.attending_field.hide_from_exhibitors, // temporary
                    answer: isJson(answer.answer) ? JSON.parse(answer.answer) : answer.answer,
                  };

                  this.visitorAnswers.push(visitorAnswer);
                }
              }
            });
          });
        }
      });
    },

    extractSelectedCheckboxes(answer: any) {
      try {
        const options = JSON.parse(answer.answer);
        const checkedOptions = options.filter((option: any) => option.checked);
        answer.answer = JSON.stringify(checkedOptions);
      } catch (error) {
        // Could not extract values
        answer.answer = '';
      }
    },

    answerIsEmpty(answer: any) {
      try {
        if (answer.field_type === 'checkbox') {
          return answer.answer.filter((a: any) => a.checked).length < 1;
        }
        return answer.answer.trim() === '';
      } catch (error) {
        // do nothing
      }
      return false;
    },

    downloadFile(fileId: number) {
      this.fileLoading = true;
      getFileById(fileId).then((response) => {
        this.fileLoading = false;
        const { file, name } = response.data;
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', name || 'file');
        link.setAttribute('target', '_blank');
        link.click();
      });
    },

    getYoutubeEmbededLink(answer: string) {
      let embeddedLink = '';
      try {
        if (answer) {
          const youtubeId = getYoutubeId(answer);

          if (youtubeId) {
            embeddedLink = `https://www.youtube.com/embed/${youtubeId}`;
          }
        }
      } catch (error) {
        // Invalid link
        embeddedLink = '';
      }
      return embeddedLink;
    },

    openChat(user: number) {
      getRocketUser(`u${user}`)
        .then((response) => {
          const { user } = response.data;
          createRocketInstantMessage(user.username).then((response) => {
            const roomId = response.data.room.rid;
            store.commit('setChatRoom', { rid: roomId, type: 'im', roomName: `${user.name}` });
            store.commit('setChatOpen');
          });
        })
        .catch((err) => {
          console.log(err);
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },

    discardChanges() {
      this.commentTextarea = {
        ...this.commentTextarea,
        value: this.visitor.comment,
      };

      this.comment = this.visitor.comment;
    },
    saveNote() {
      this.commentLoading = true;
      const data = {
        exhibitor: this.exhibitor,
        source_id: this.visitor.id || 0,
        source_type: 'visitor',
        comment: this.comment,
        id: this.visitor.comment_id,
      };
      if (this.visitor.comment_id) {
        updateExhibitorComment(data).then(() => {
          this.commentLoading = false;
          store.commit('addPopup', {
            message: this.translations.common.saved,
            type: 'success',
            autohide: true,
          });
        });
      } else {
        createExhibitorComment(data).then(() => {
          this.commentLoading = false;
          store.commit('addPopup', {
            message: this.translations.common.saved,
            type: 'success',
            autohide: true,
          });
        });
      }
    },

    cardConfig(visitor: IVisitor) {
      const config: IUserCard = {
        name: visitor.name,
        detail: visitor.email,
        picture: visitor.profile_picture,
      };
      return config;
    },
  },
});
