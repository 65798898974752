












































































import { defineComponent, PropType } from '@vue/composition-api';
import router from '@/router';
import { IUserCard } from '.';

// @ is an alias to /src
export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IUserCard>,
      required: true,
    },
  },
  setup(props) {
    const { config } = props;
    const redirectToProfile = () => {
      if (config.redirect) {
        window.open(`${config.redirect}`, '_blank');
      }
      if (config.route) {
        router.push(config.route);
      }
    };

    return { redirectToProfile };
  },
});
